.menu-icon {
    margin-right: 12px;
}

.menu-icon-small {
    margin-right: 12px;
    margin-left: 2px;
}

.nav-text-small {
    font-size: 14px;
}
