.asset-grid-h {
    max-height: 60vh;
}

.assets-templates-filters {
    display: flex;
    align-items: center;
    justify-content: right;

    label {
        margin: 0;
    }

    .assets-templates-multiselect {
        max-width: 320px;
        display: block;
        margin-left: 25px;

        > div:first-child {
            height: 38px;
            overflow-y: scroll;

            > span:first-child {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}
