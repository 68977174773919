:root {
    /* TODO: Maybe it would be a good idea to remove the `position: fixed` from the nav bar? If it were done, then the hacks that use this constant would be unnecessary. */
    --nav-bar-height: 57px;
    --dialog-z-index: 10001;
}

table {
    table-layout: fixed;
}

.left2 {
    margin-left: 2px;
}

.left5 {
    margin-left: 5px;
}

.left18 {
    margin-left: 18px;
}

.right2 {
    margin-right: 2px;
}

.top5 {
    margin-top: 5px;
}
.top7 {
    margin-top: 7px;
}
.top10 {
    margin-top: 10px;
}
.top15 {
    margin-top: 15px;
}
.top17 {
    margin-top: 17px;
}
.top30 {
    margin-top: 30px;
}

.bottom5 {
    margin-bottom: 5px;
}
.bottom7 {
    margin-bottom: 7px;
}
.bottom10 {
    margin-bottom: 10px;
}
.bottom15 {
    margin-bottom: 15px;
}
.bottom17 {
    margin-bottom: 17px;
}
.bottom30 {
    margin-bottom: 30px;
}
.left5 {
    margin-left: 5px;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    display: flex;
    margin: 20px 0;
    padding-left: 20px;
}

ul.timeline > li:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.display {
    display: block;
}

/*
 * Sidebar
 */
.sidebar {
    z-index: 100; /* Behind the navbar */
    padding: var(--nav-bar-height) 0 0; /* Height of navbar */
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
    position: relative;
    top: var(--nav-bar-height);
    height: calc(100vh - 57px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.full-height-without-nav-bar {
    height: calc(100vh - var(--nav-bar-height));
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
    padding-left: 14px;
}

.sidebar .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
    padding-top: var(--nav-bar-height); /* Space for fixed navbar */
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.toast-wrapper {
    position: absolute;
    top: 67px;
    right: 10px;
    z-index: var(--dialog-z-index);
}

.photo-card {
    width: 18rem;
}

td .is-invalid {
    width: fit-content;
}

.form-control.is-invalid {
    width: 100%;
}

.is-invalid input {
    margin-right: 20px;
    margin-left: 2px;
}

.is-invalid > .react-select__control {
    border-color: var(--red);
}

.table-responsive.is-invalid {
    border: 1px solid var(--red);
}

.report-table {
    width: 300px;
}

.breadcrumb-item.activity-nav + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "";
}

.field-disable input {
    background-color: #e9ecef;
    opacity: 1;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field-disable input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    border-top-color: rgb(128, 189, 255);
    border-right-color: rgb(128, 189, 255);
    border-bottom-color: rgb(128, 189, 255);
    border-left-color: rgb(128, 189, 255);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.field-enable input {
    background-color: #fff;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field-enable input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    border-top-color: rgb(128, 189, 255);
    border-right-color: rgb(128, 189, 255);
    border-bottom-color: rgb(128, 189, 255);
    border-left-color: rgb(128, 189, 255);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.scheduler-heading {
    padding: 0.375rem 0rem;
}

.side-column-width {
    width: 200px;
}

.main-width {
    width: calc(100vw - 200px);
}

.narrow {
    width: 48px;
}

.width-transition {
    transition: width 0.3s;
}

.rotated-180 {
    transform: rotate(180deg);
}

.transition-transform {
    transition: transform 0.3s;
}

.grid-header {
    background: #e9ecef;
}

.border-right {
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
/* Hacking KendoReact Data Grid...
 * The arrow for sorting a column is incorrectly offset when using the Bootstrap theme.
 */
.k-grid-header .k-sorted > .k-link {
    display: flex;
    align-items: center;
}

.overflow-visible,
.k-grid td.overflow-visible {
    overflow: visible;
}

.k-filtercell-operator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
}

/* Hacking KendoReact Data Grid...
 * .k-animation-container class is used by KendoReact for Data Grid column filter popups and its z-index is 100.
 * .k-dialog-wrapper class is used by KendoReact for dialogs and its z-index is 10001.
 * When Data Grid with filters is used within a Dialog, then column filter popup is hidden under the dialog.
 */
.k-animation-container {
    z-index: var(--dialog-z-index);
}

.nav-sub-menu {
    border-radius: 0;
}

.nav-sub-menu .list-group-item {
    padding: 0;
}

.nav-sub-menu .nav-link {
    padding-left: 20px;
    font-size: 14px;
}

.form-footer-control {
    display: flex;
    flex-direction: row;
}

.form-footer-control > :first-child {
    margin-left: auto;
}

.form-footer-control > .alert {
    padding: 6px 20px;
    margin-right: 10px;
}

.form-footer-control > .alert > button {
    padding: 5px 10px;
    line-height: 15px;
    font-size: 15px;
    margin-left: 15px;
}

.h-100-without-topnav {
    height: calc(100% - var(--nav-bar-height));
}
