@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$highlight-color-hover: #eff3f5;
$highlight-color-focus: #e1e3eb;
$highlight-color-active: #dee3eb;

// Inputs

$border-color-error: $red;

// Tables & table-looking components with rows (with header and alternating rows colors)

$rows-header-bg: $gray-200;
$row-bg: $white;
$row-odd-bg: $gray-100;
