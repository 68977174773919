.tracking-object-details-page {
    .preview {
        padding: 0.5rem 0;
        border: 1px solid rgb(221, 221, 221);

        .preview-title {
            background-color: #f8f9fa;
            margin: -0.5rem 0;
            padding: 0.5rem;
        }

        .item {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;

            &:not(:last-child) {
                border-bottom: 1px solid rgb(221, 221, 221);
            }

            &:nth-child(even) {
                background: #f8f9fa;
            }
        }

        .category-title {
            margin: 1.5rem 0.5rem 0.5rem;
        }
    }
}
