.examinations-grid-h {
    position: relative;
    max-height: 60vh;
    min-height: 6rem;

    .k-grid-table {
        min-height: 40px;
    }

    .k-grid {
        tr.closed {
            color: #155724;
            background-color: #d4edda;

            &.k-alt {
                background-color: #d4edda;
            }

            &:hover {
                background-color: #dcf5e3;
            }
        }
    }
}
