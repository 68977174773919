.tracking-object-details-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 20px;
    margin: 2rem 0 1rem;

    .title {
        h1 {
            margin: 0;
        }

        h2 {
            font-size: 1rem;
            font-weight: normal !important;
        }
    }
}
