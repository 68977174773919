.examinations-grid-h {
    position: relative;
    max-height: 60vh;

    .k-grid {
        tr.completed {
            color: #155724;
            background-color: #d4edda;

            &.k-alt {
                background-color: #d4edda;
            }

            &:hover {
                background-color: #dcf5e3;
            }
        }
    }
}
