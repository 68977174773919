@import "../../../styles/variables";

.is-invalid {
    .custom-select__control {
        border-color: $border-color-error;
    }
}

.form-group .custom-select__control {
    &--is-disabled {
        color: #495057;
        background-color: #e9ecef;
        border-color: #ced4da;

        .custom-select__single-value {
            color: #495057;
        }

        .custom-select__indicator-separator {
            background-color: #ced4da;
        }
    }
}
